<script>
/**
 * name：
 * user：sa0ChunLuyu
 * date：2022-03-16 09:46:12
 */
export default {
  data() {
    return {
      jiaoyanren_list: [],
      choose_show: false,


      er_info: false,
      pdf_visible: false,
      callbackyiqi_push_show: false,
      callback_push_show: false,
      callbackyiqi_push: [],
      callback_push: [],
      value_mark: '',
      pdf_url: '',
      options: {
        container: 'luckysheet', //luckysheet为容器id
        lang: 'zh',
        title: '',
        showinfobar: false,
        showtoolbar: false,
        showtoolbarConfig: {
          undoRedo: true, //撤销重做，注意撤消重做是两个按钮，由这一个配置决定显示还是隐藏
          font: true, // '字体'
          fontSize: true, // '字号大小'
          bold: true, // '粗体 (Ctrl+B)'
          italic: true, // '斜体 (Ctrl+I)'
          //strikethrough: true, // '删除线 (Alt+Shift+5)'
          underline: true, // '下划线 (Alt+Shift+6)'
          textColor: true, // '文本颜色'
          fillColor: true, // '单元格颜色'
          border: true, // '边框'
          image: true,
          mergeCell: true, // '合并单元格'
          horizontalAlignMode: true, // '水平对齐方式'
          verticalAlignMode: true, // '垂直对齐方式'
          print: false, // '打印'
        },
        column: 50,
        row: 50,
        showstatisticBar: false,
        enableAddRow: false,
        enableAddBackTop: false,
        userInfo: false,
        showsheetbar: false,
        cellRightClickConfig: {
          copy: true, // 复制
          copyAs: false, // 复制为
          paste: true, // 粘贴
          insertRow: true, // 插入行
          insertColumn: true, // 插入列
          deleteRow: true, // 删除选中行
          deleteColumn: false, // 删除选中列
          deleteCell: false, // 删除单元格
          hideRow: false, // 隐藏选中行和显示选中行
          hideColumn: false, // 隐藏选中列和显示选中列
          rowHeight: true, // 行高
          columnWidth: true, // 列宽
          clear: false, // 清除内容
          matrix: false, // 矩阵操作选区
          sort: false, // 排序选区
          filter: false, // 筛选选区
          chart: false, // 图表生成
          image: true, // 插入图片
          link: false, // 插入链接
          data: false, // 数据验证
          cellFormat: false // 设置单元格格式
        },
        sheetFormulaBar: false
      },
      tr_arr: ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    returnYpcontentXy(xy) {
      if (xy === '') return '';
      let xy_ = JSON.parse(xy)
      let t1 = `${this.tr_arr[xy_.column[0] + 1]}${xy_.row[0] + 1}`;
      let t2 = `${this.tr_arr[xy_.column[1] + 1]}${xy_.row[1] + 1}`;
      if (t1 === t2) {
        return t1;
      } else {
        return `${t1}-${t2}`;
      }
    },
    mountedDo() {
      this.getJiaoyanrenList()
      this.pushExcelJson()
      this.getTplInfo()
    },
    getSaveData() {
      this.$sa0.post({
        url: this.$api('检测报告流程，获取保存内容'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = response.data.contents
            this.pushExcelJson(table ? {sheets: [JSON.parse(table).data[0]]} : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTplInfo() {
      // 检测报告流程，获取表格模板
      this.$sa0.post({
        url: this.$api('检测报告流程，获取表格模板'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = JSON.parse(response.data.table)
            this.table_type = response.data.type
            if (response.data.content1) {
              this.pushExcelJson(table ? {sheets: table.data} : false);
            } else {
              this.pushExcelJson(table ? {sheets: [table]} : false);
            }
            if (response.data.er_info) {
              this.er_info = response.data.er_info
              this.getSaveData()
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    pushCallbackData() {
      this.callback_push.map((item) => {
        let range = JSON.parse(item.range)
        let value = item.value_data[Number(item.value_choose)].data
        luckysheet.setCellValue(range.row[0], range.column[0], {
          m: `${value || ''}`,
          v: `${value || ''}`,
        })
      })
      this.callback_push_show = false
    },
    pushdata(data, index, value) {
      value.map((item) => {
        let v = ''
        // item_name     out_value   bj bz_value
        if (item.type === 1) {
          v = data.item_name
        }
        if (item.type === 2) {
          v = data.out_value
        }
        if (item.type === 3) {
          switch (data.result_type) {
            case 1:
              v = data.limit + '~' + data.upper
              break;
            case 2:
              v = data.bz_value
              break;
            default:
              v = data.bj + '' + data.bz_value
          }
        }

        let range = JSON.parse(item.area)
        let value = v
        luckysheet.setCellValue(range.row[0] + index, range.column[0], {
          m: `${value || ''}`,
          v: `${value || ''}`,
        })
      })
      console.log({
        data, index, value
      })
    },
    makePushdata({info, data, list}) {
      let this_ = this
      let area = JSON.parse(info.area)
      let push_line = area.row[1] - area.row[0] + 1
      let group_line = data.length - 1
      let line_data = luckysheet.getRangeValue({
        range: area
      })
      for (let i = 0; i < group_line; i++) {
        let def_line = i * push_line
        let start_line = area.row[1] + def_line
        luckysheet.insertRow(start_line, {
          number: push_line,
          success: () => {
            luckysheet.cancelRangeMerge({
              range: {row: [start_line + 1, start_line + push_line + 1], column: area.column}
            })

            line_data.map((line, key) => {
              line.map((item) => {
                if (item) {
                  if (typeof item.mc !== 'undefined') {
                    if (typeof item.mc.rs !== 'undefined') {
                      let r = item.mc.r
                      let c = item.mc.c
                      let rs = item.mc.rs
                      let cs = item.mc.cs
                      luckysheet.setRangeMerge('all', {
                        range: {
                          column: [c, c + cs - 1],
                          row: [r + def_line + 1, r + def_line + 1 + rs - 1]
                        }
                      })
                    }
                  }
                }
              })
            })
          }
        })
      }
      setTimeout(() => {
        for (let i = 0; i <= group_line; i++) {
          this.pushdata(data[i], i, list)
        }
      }, 100)
      console.log(area)
      console.log(line_data)
      //
      //
      // console.log(info)
      // console.log(data)
      // console.log(list)
    },
    getOrSetData() {
      this.$sa0.post({
        url: this.$api('检测报告：获取原始记录单检测数据'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.makePushdata(response.data)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
      // this.$sa0.post({
      //   url: this.$api('检测报告流程，获取原始记录单数据'), data: {
      //     id: this.$route.params.id
      //   }
      // }).then((response) => {
      //   this.$sa0.response({
      //     response: response.data,
      //     then: (response) => {
      //       this.callback_push = response.data.list
      //       this.callback_push_show = true
      //       // response.data.list.map((item) => {
      //       //   luckysheet.setCellValue(item.range.row[0], item.range.column[0], {
      //       //     m: `${item.value}`,
      //       //     v: `${item.value}`,
      //       //   })
      //       // })
      //     },
      //     error: (response) => {
      //       layer.msg(response.message)
      //     },
      //   })
      // })
    },
    getTplData() {
      this.$sa0.post({
        url: this.$api('检测报告流程，获取动态数据'), data: {
          id: this.$route.params.id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.dataBackInput(response.data.list)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    dataBackInput(list) {
      list.map((item) => {
        luckysheet.setCellValue(item.area.row[0], item.area.column[0], {
          m: `${item.data}`,
          v: `${item.data}`,
        })
      })
    },
    onClosePdf() {
      this.pdf_visible = false
    },
    saveExcel() {
      // 检测报告流程，保存报告
      this.$sa0.post({
        url: this.$api('检测报告流程，保存报告'),
        data: {
          content: JSON.stringify(luckysheet.toJson()),
          id: this.$route.params.id,
          url: this.pdf_url,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            window.location.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    showThisPDf() {
      if (this.er_info && this.er_info.state !== 1) {
        this.pdf_url = this.er_info.pdf_url
        this.pdf_visible = true
      } else {
        this.$sa0.post({
          url: this.$api('检测报告流程，打印预览'),
          data: {
            content: JSON.stringify(luckysheet.toJson()),
            id: this.$route.params.id
          },
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              this.pdf_url = response.data.pdf_url
              this.pdf_visible = true
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    },
    pushExcelJson(exportJson = false) {
      let options = this.options
      if (exportJson) {
        options.data = exportJson.sheets
      }
      luckysheet.destroy();
      luckysheet.create(options);
    },
    getJiaoyanrenList() {
      this.$sa0.post({
        url: this.$api('任务:校验人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toCheckClick() {
      this.choose_show = true
    },
    toCheck() {
      this.$sa0.post({
        url: this.$api('检测报告流程，报告提交审核'),
        data: {
          id: this.$route.params.id,
          check_id: this.er_info.check_id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.choose_show = false
            window.location.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onCloseCallback() {
      this.callback_push_show = false
    },
    getYiqisetInfo() {
      // 检测报告：仪器回填数据列表
      this.$sa0.post({
        url: this.$api('检测报告：仪器回填数据列表'),
        data: {
          id: this.$route.params.id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.callbackyiqi_push = response.data.list.map((item) => {
              item.mark_value = this.value_mark
              return item;
            })
            this.callbackyiqi_push_show = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    onCloseyiqiCallback() {
      this.callbackyiqi_push_show = false
    },
    pushyiqiCallbackData() {
      // read_arr
      // callbackyiqi_push
      // 检测报告：获取仪器回填数据
      let read_arr = this.callbackyiqi_push.map((item) => {
        return [item['mark_value'], '', item['mark']]
      })
      this.$sa0.post({
        url: this.$api('检测报告：获取仪器回填数据'),
        data: {
          read_arr: read_arr
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let data = response.data.list
            this.callbackyiqi_push.map((item) => {
              let key = `${item['mark_value']}_${item['mark']}`
              let value = data[key].value
              let range = JSON.parse(item.range)
              luckysheet.setCellValue(range.row[0], range.column[0], {
                m: `${value}`,
                v: `${value}`,
              })
            })
            this.onCloseyiqiCallback()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getTpltable() {
      // 检测报告：重新获取模板表格
      this.$sa0.post({
        url: this.$api('检测报告：重新获取模板表格'),
        data: {
          id: this.$route.params.id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let table = JSON.parse(response.data.table)
            this.pushExcelJson(table ? {sheets: [table]} : false);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <a-modal v-model:visible="choose_show" title="确认校验人" @ok="toCheck()">
      <div>
        <a-select v-model="er_info.check_id" class="w-full">
          <a-select-option :value="0">暂无</a-select-option>
          <a-select-option v-for="(jyi,jyk) in jiaoyanren_list" :value="jyi.id">
            {{ jyi.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>

    <a-drawer
        title="数据回填"
        width="100%"
        placement="right"
        :visible="callbackyiqi_push_show"
        @close="onCloseyiqiCallback"
    >
      <div>
        <table class="layui-table" lay-size="sm">
          <thead>
          <tr>
            <th>位置</th>
            <th>样本</th>
            <th>标识</th>
            <th>名称</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(ci,ck) in callbackyiqi_push" :key="ck">
            <td>{{ returnYpcontentXy(ci.range) }}</td>
            <td>
              <a-input v-model="ci.mark_value"></a-input>
            </td>
            <td>
              <a-input v-model="ci.mark"></a-input>
            </td>
            <td>{{ ci.name }}</td>
          </tr>
          </tbody>
        </table>
        <a-button type="primary" @click="pushyiqiCallbackData()">确定</a-button>
      </div>
    </a-drawer>
    <a-drawer
        title="数据回填"
        width="100%"
        placement="right"
        :visible="callback_push_show"
        @close="onCloseCallback"
    >
      <div>
        <table class="layui-table" lay-size="sm">
          <thead>
          <tr>
            <th>位置</th>
            <th>数据</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(ci,ck) in callback_push" :key="ck">
            <td>{{ returnYpcontentXy(ci.range) }}</td>
            <td>
              <a-select style="width: 200px;" size="small" v-model="callback_push[ck].value_choose">
                <a-select-option v-for="(mi,mk) in ci.value_data" :value="mk">
                  {{ mi.s_number }} {{ mi.value_name }} <b>{{ mi.data }}</b>
                </a-select-option>
              </a-select>
            </td>
          </tr>
          </tbody>
        </table>
        <a-button type="primary" @click="pushCallbackData()">确定</a-button>
      </div>
    </a-drawer>
    <a-drawer
        title="报告预览"
        width="100%"
        placement="right"
        :visible="pdf_visible"
        @close="onClosePdf"
    >
      <div>
        <iframe :src="pdf_url" frameborder="0" style="width: 100%;height: calc(100vh - 140px);"></iframe>
        <div class="mt-2">
          <a-button :disabled="er_info && er_info.state !== 1" @click="saveExcel()">保存</a-button>
        </div>
      </div>
    </a-drawer>
    <div>
      <div id="luckysheet" class="luckysheet_wrapper"></div>
      <div class="button_wrapper">
        <a-space>
          <a-button :disabled="er_info && er_info.state !== 1" type="primary" @click="getTpltable()">重新读取模板
          </a-button>
          <a-button :disabled="er_info && er_info.state !== 1" type="primary" @click="getTplData()">获取基础动态数据
          </a-button>
          <a-button :disabled="er_info && er_info.state !== 1" type="primary" @click="getOrSetData()">获取原始记录单数据
          </a-button>
          <a-button type="primary" @click="showThisPDf()">报告预览</a-button>
          <div v-if="er_info">
            <a-button :disabled="er_info.state !== 1" type="primary" @click="toCheckClick()">提交审核</a-button>
          </div>
        </a-space>
        <!--        <div class="mt-2">-->
        <!--          <a-space>-->
        <!--            <b>仪器读取标识</b>-->
        <!--            <a-input :disabled="er_info && er_info.state !== 1" v-model="value_mark"></a-input>-->
        <!--            <a-button :disabled="er_info && er_info.state !== 1" type="primary"-->
        <!--                      @click="getYiqisetInfo()">读取检测仪器数据-->
        <!--            </a-button>-->
        <!--          </a-space>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<style scoped>
.button_wrapper {
  position: absolute;
  left: 840px;
  top: 0;
}

.luckysheet_wrapper {
  position: absolute;
  width: 800px;
  top: 0;
  bottom: 50px;
}
</style>
